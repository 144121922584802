.guide-section {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 1;
      overflow-y: scroll;
      line-height: 1.6;
      scroll-behavior: smooth;
}

.guide-section::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
}

.guide-section::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
}

.guide-section::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: white;
}

.guide {
      padding: 10px;
      border-radius: 20px;
      width: 90%;
      margin-top: 50px;
      height: fit-content;
}

.guide-heading {
      color: white;
      font-size: 2em;
}

.container {
      margin: 10px;
      padding: 10px;
      border-radius: 10px;
}

.h2-heading {
      margin-top: 5px;
}
.h2-heading-theme-0 {
      border-bottom: 2px solid rgba(255, 0, 0, 0.5);
}
.h2-heading-theme-1 {
      border-bottom: 2px solid rgba(0, 255, 0, 0.5);
}

.h2-heading-theme-2 {
      border-bottom: 2px solid rgba(128, 0, 128, 0.5);
}

.h2-heading-theme-3 {
      border-bottom: 2px solid rgba(255, 255, 0, 0.5);
}

.h2-heading-theme-4 {
      border-bottom: 2px solid rgba(0, 255, 255, 0.5);
}

.p1 {
      margin: 10px;
}

ul {
      padding-left: 30px;
}

li {
      margin-top: 5px;
}

.controller-layout {
      color: inherit;
}
.controller-layout:hover {
      text-decoration: underline;
}

@media (max-width: 800px) {
      .guide-section {
            font-size: 14px;
      }
      /* .guide {
            margin: 0;
            margin-top: 50px;
      } */
      .container {
            margin-left: 10px;
            margin-right: 0;
      }
      .guide-section::-webkit-scrollbar {
            width: 2px;
      }
      .guide-section::-webkit-scrollbar-thumb {
            background-color: transparent;
      }
}

.stats {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 30px;
      overflow-x: scroll;
}

.stats::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
}

.stats::-webkit-scrollbar {
      width: 5px;
      height: 0;
      background-color: transparent;
}

.stats::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: white;
}

.average-rating {
      height: 100px;
      min-width: 150px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      flex-direction: column;
      gap: 20px;
      font-size: 13px;
}

.item-2 {
      width: 250px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      border-radius: 10px;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
}

.average-rating-theme-0,
.item-2-theme-0 {
      background-color: rgba(255, 0, 0, 0.1);
}
.average-rating-theme-1,
.item-2-theme-1 {
      background-color: rgba(0, 255, 0, 0.1);
}
.average-rating-theme-2,
.item-2-theme-2 {
      background-color: rgba(128, 0, 128, 0.1);
}
.average-rating-theme-3,
.item-2-theme-3 {
      background-color: rgba(255, 255, 0, 0.1);
}
.average-rating-theme-4,
.item-2-theme-4 {
      background-color: rgba(0, 255, 255, 0.1);
}

.sub-item-2 {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
}

.difficulty {
      width: 60px;
}
.attempted-count {
      width: 30px;
      cursor: pointer;
}
.difficulty-average-rating {
      width: 30px;
      cursor: pointer;
}
.filler {
      width: 50px;
      height: 5px;
      border-radius: 5px;
}
.filler-theme-0 {
      background-color: rgba(255, 0, 0, 0.7);
}
.filler-theme-1 {
      background-color: rgba(0, 255, 0, 0.7);
}
.filler-theme-2 {
      background-color: rgba(128, 0, 128, 0.7);
}
.filler-theme-3 {
      background-color: rgba(255, 255, 0, 0.7);
}
.filler-theme-4 {
      background-color: rgba(0, 255, 255, 0.7);
}

.connected-controller {
      margin: 5px;
      padding: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 700px;
      border-radius: 20px;
      text-align: center;
      position: relative;
}

.container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 10px;
      gap: 10px;
      align-items: center;
}

.image-loader {
      width: 200px;
      height: 134px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      border-radius: 10px;
      color: white;
}

.gamepad-image {
      width: 200px;
}

.gamepad-not-connected {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 20px;
}

.setting-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
}

.setting {
      font-style: italic;
      font-weight: bold;
      font-size: 15px;
      background-color: white;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 20px;
      color: black;
      margin: 5px;
      border: 1px solid black;
}

.setting:focus {
      background-color: black;
      color: white;
}

@media (max-width: 900px) {
      .connected-controller {
            flex-direction: column;
            flex-grow: 1;
            max-width: 400px;
      }
      .gamepad-image {
      }
}

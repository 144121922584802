.setting-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 300px;
      padding-left: 5px;
}

.setting {
      font-style: italic;
      font-weight: bold;
      border: 2px solid transparent;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 20px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      width: 110px;
      font-size: 15px;
}

@media (max-width: 500px) {
      .setting {
            font-size: 13px;
      }
}

.player {
      display: flex;
      flex-direction: column;
      align-items: center;
}

.player-result {
      color: white;
      padding: 5px;
      margin-bottom: 5px;
}

.player-result-theme-0 {
      border: 2px solid rgba(255, 0, 0, 1) !important;
}
.player-result-theme-1 {
      border: 2px solid rgba(0, 255, 0, 1) !important;
}

.player-result-theme-2 {
      border: 2px solid rgba(128, 0, 128, 1) !important;
}

.player-result-theme-3 {
      border: 2px solid rgba(255, 255, 0, 1) !important;
}
.player-result-theme-4 {
      border: 2px solid rgba(0, 255, 255, 1) !important;
}

.donuts-progress-container {
      display: flex;
      justify-content: space-around;
      font-size: 0.75em;
      width: 220px;
      margin-bottom: 5px;
}

.donut-container {
      display: flex;
      flex-direction: column;
      gap: 3px;
      align-items: center;
}

.player-number {
      text-align: center;
      font-size: 1em;
      color: white;
      margin: 5px;
      margin-bottom: 10px;
      font-weight: bold;
}

.player-stat {
      margin: 5px;
      font-size: 0.75em;
      display: flex;
      justify-content: space-between;
      width: 220px;
      padding: 3px;
}

.score-animation-section {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000;
      flex-direction: column;
}

.score-update-animation {
      animation: type 1.5s linear;
      transform: scale(0);
      color: white;
}

@keyframes type {
      from {
            transform: scale(1);
            opacity: 1;
      }
      to {
            transform: scale(2);
            opacity: 0;
      }
}

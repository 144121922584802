.arena-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      overflow: hidden;
      /* flex-direction: column; */
}

.arena-overlay-split-screen {
      justify-content: center;
}

.single-player-container {
      z-index: 1;
      margin-top: 50px;
      margin-left: 20px;
      margin-right: 20px;
}

.touch-controls-on {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      justify-content: space-between;
}

.split-screen-container {
      z-index: 1;
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      gap: calc(100vw * 0.2);
      align-items: center;
}

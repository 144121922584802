.donut {
      position: relative;
      height: min-content;
      width: max-content;
}
.box {
      width: 25px;
      height: 25px;
      /* background: conic-gradient(white 270deg, transparent); */
      border-radius: 50%;
      /* animation: donut-progress 3s linear; */
}

.inner-box {
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      position: absolute;
      border-radius: 50%;
      background-color: black;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
}

/* @keyframes donut-progress {
      from {
            background: conic-gradient(white 0deg, transparent -360deg);
      }
      to {
            background: conic-gradient(white 300deg, transparent -360deg);
      }
} */

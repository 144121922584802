.touch-controls-section {
      display: flex;
      /* width: 100%; */
      gap: 80px;
      justify-content: center;
}

.controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
}

.button {
      background-color: red;
      padding: 2px;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      color: black;
}

.middle-row {
      display: flex;
      gap: 65px;
}

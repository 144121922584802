.start-timer-section {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
      animation: opacity-animation 0.5s ease-in-out;
}

@keyframes opacity-animation {
      from {
            opacity: 0;
      }
      to {
            opacity: 1;
      }
}

.time {
      color: white;
      font-size: 100px;
}

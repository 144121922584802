.page {
      width: 100vw;
      height: 100svh;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: monospace;
      animation: none !important;
      font-size: 16px;
}

.main {
      display: flex;
      width: 100%;
      justify-content: space-around;
}

@media (max-width: 650px) {
      .page {
            font-size: 13px;
      }
}

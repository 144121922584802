.option {
      z-index: 100;
      position: relative;
      padding-top: 2px;
      padding-bottom: 2px;
      border: 2px solid transparent;
      border-bottom: 2px solid black;
}

.option-theme-0:focus {
      box-shadow: 0 0 10px rgba(255, 0, 0, 1);
      background-color: rgba(255, 0, 0, 0.3);
      border: 2px solid rgba(255, 0, 0, 1);
}
.option-theme-1:focus {
      border: 2px solid rgba(0, 255, 0, 1);
      background-color: rgba(0, 255, 0, 0.3);
      box-shadow: 0 0 10px rgba(0, 255, 0, 1);
}
.option-theme-2:focus {
      border: 2px solid rgba(128, 0, 128, 1);
      background-color: rgba(128, 0, 128, 0.3);
      box-shadow: 0 0 10px rgba(128, 0, 128, 1);
}
.option-theme-3:focus {
      border: 2px solid rgba(255, 255, 0, 1);
      background-color: rgba(255, 255, 0, 0.3);
      box-shadow: 0 0 10px rgba(255, 255, 0, 1);
}
.option-theme-4:focus {
      border: 2px solid rgba(0, 255, 255, 1);
      background-color: rgba(0, 255, 255, 0.3);
      box-shadow: 0 0 10px rgba(0, 255, 255, 1);
}

* {
      box-sizing: border-box;
      margin: 0;
      border: none;
      text-decoration: none;
      outline: none;
}
body {
      overflow-x: hidden;
}

.player-section {
      display: flex;
      position: relative;
}
.game-over-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      z-index: 1;
      animation: opacity-animation 1s ease-in-out;
}

@keyframes opacity-animation {
      from {
            opacity: 0;
      }
      to {
            opacity: 1;
      }
}

.game-info {
      color: white;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 135px;
      flex-wrap: wrap;
}

.game-info-theme-0 {
      border: 2px solid rgba(255, 0, 0, 1);
      box-shadow: 0 0 5px rgba(255, 0, 0, 1);
}

.game-info-theme-1 {
      border: 2px solid rgba(0, 255, 0, 1);
      box-shadow: 0 0 3px rgba(0, 255, 0, 1);
}
.game-info-theme-2 {
      border: 2px solid rgba(128, 0, 128, 1);
      box-shadow: 0 0 5px rgba(128, 0, 128, 1);
}

.game-info-theme-3 {
      border: 2px solid rgba(255, 255, 0, 1);
      box-shadow: 0 0 3px rgba(255, 255, 0, 1);
}
.game-info-theme-4 {
      border: 2px solid rgba(0, 255, 255, 1);
      box-shadow: 0 0 5px rgba(0, 255, 255, 1);
}

.info-item {
      margin: 5px;
      padding: 5px;
      width: 100px;
      color: white;
      font-size: 12px;
      text-align: center;
}

.info-item-theme-0 {
      /* border: 2px solid rgba(255, 0, 0, 1); */
      background-color: rgba(255, 0, 0, 0.5);
      box-shadow: 0 0 5px rgba(255, 0, 0, 1);
}
.info-item-theme-1 {
      /* border: 2px solid rgba(0, 255, 0, 1); */
      background-color: rgba(0, 255, 0, 0.3);
      box-shadow: 0 0 3px rgba(0, 255, 0, 1);
}

.info-item-theme-2 {
      /* border: 2px solid rgba(128, 0, 128, 1); */
      background-color: rgba(128, 0, 128, 0.5);
      box-shadow: 0 0 5px rgba(128, 0, 128, 1);
}
.info-item-theme-3 {
      /* border: 2px solid rgba(255, 255, 0, 1); */
      background-color: rgba(255, 255, 0, 0.3);
      box-shadow: 0 0 3px rgba(255, 255, 0, 1);
}
.info-item-theme-4 {
      /* border: 2px solid rgba(0, 255, 255, 1); */
      background-color: rgba(0, 255, 255, 0.5);
      box-shadow: 0 0 5px rgba(0, 255, 255, 1);
}

.score-update-animation {
      animation: type 0.2s linear;
      transform: scale(1);
}

@keyframes type {
      from {
            transform: scale(1);
      }
      to {
            transform: scale(1.5);
      }
}

.container {
      position: relative;
}
/* .shaking {
      animation: shaking-animation 0.1s linear;
}
@keyframes shaking-animation {
      0%,
      40%,
      80% {
            transform: rotate(2deg);
      }
      20%,
      60%,
      100% {
            transform: rotate(-2deg);
      }
} */

.container-theme-0 {
      background-color: rgba(12, 12, 12);
      border: 2px solid rgba(255, 0, 0, 1);
      box-shadow: 0 0 5px rgba(255, 0, 0, 1);
}

.container-theme-1 {
      background-color: rgba(12, 12, 12);
      border: 2px solid rgba(0, 255, 0, 1);
      box-shadow: 0 0 3px rgba(0, 255, 0, 1);
}

.container-theme-2 {
      background-color: rgba(12, 12, 12);
      border: 2px solid rgba(128, 0, 128, 1);
      box-shadow: 0 0 5px rgba(128, 0, 128, 1);
}

.container-theme-3 {
      background-color: rgba(12, 12, 12);
      border: 2px solid rgba(255, 255, 0, 1);
      box-shadow: 0 0 3px rgba(255, 255, 0, 1);
}

.container-theme-4 {
      background-color: rgba(12, 12, 12);
      border: 2px solid rgba(0, 255, 255, 1);
      box-shadow: 0 0 5px rgba(0, 255, 255, 1);
}

.row {
      display: flex;
      position: relative;
}
.column {
      width: 26px;
      height: 26px;
      border: 2px solid rgb(6, 6, 6);
}
.laser-beam {
      width: 0px;
      height: 3px;
      transition: width 0.016s linear;
      position: absolute;
      top: 50%;
      background-color: rgb(219 209 209);
      box-shadow: 0 0 10px rgb(232 221 221);
}
.menu {
      position: absolute;
      top: 0;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-evenly;
      align-items: center;
      display: flex;
      background-color: black;
      display: none;
}

.menu-toggle {
      display: none;
}

.menu-item {
      width: 100px;
      padding: 5px;
      color: white;
      cursor: pointer;
}

/* theme-0 */
.l-tetromino-active-theme-0,
.t-tetromino-active-theme-0,
.square-tetromino-active-theme-0,
.line-tetromino-active-theme-0,
.z-tetromino-active-theme-0 {
      border: 2px solid rgba(255, 0, 0, 1);
      background-color: rgba(255, 0, 0, 0.5);
      box-shadow: 0 0 10px rgba(255, 0, 0, 1);
}

/* theme-1 */
.l-tetromino-active-theme-1,
.t-tetromino-active-theme-1,
.square-tetromino-active-theme-1,
.line-tetromino-active-theme-1,
.z-tetromino-active-theme-1 {
      border: 2px solid rgba(0, 255, 0, 1);
      background-color: rgba(0, 255, 0, 0.5);
      box-shadow: 0 0 10px rgba(0, 255, 0, 1);
}

/* theme-2 */
.l-tetromino-active-theme-2,
.t-tetromino-active-theme-2,
.square-tetromino-active-theme-2,
.line-tetromino-active-theme-2,
.z-tetromino-active-theme-2 {
      border: 2px solid rgba(128, 0, 128, 1);
      background-color: rgba(128, 0, 128, 0.5);
      box-shadow: 0 0 10px rgba(128, 0, 128, 1);
}

/* theme-3 */
.l-tetromino-active-theme-3,
.t-tetromino-active-theme-3,
.square-tetromino-active-theme-3,
.line-tetromino-active-theme-3,
.z-tetromino-active-theme-3 {
      border: 2px solid rgba(255, 255, 0, 1);
      background-color: rgba(255, 255, 0, 0.5);
      box-shadow: 0 0 10px rgba(255, 255, 0, 1);
}

.l-tetromino-active-theme-4,
.t-tetromino-active-theme-4,
.square-tetromino-active-theme-4,
.line-tetromino-active-theme-4,
.z-tetromino-active-theme-4 {
      border: 2px solid rgba(0, 255, 255, 1);
      background-color: rgba(0, 255, 255, 0.5);
      box-shadow: 0 0 10px rgba(0, 255, 255, 1);
}

.l-tetromino-active-theme-5 {
      border: 2px solid rgba(255, 0, 0, 1);
      background-color: rgba(255, 0, 0, 0.3);
      box-shadow: 0 0 10px rgba(255, 0, 0, 1);
}

.t-tetromino-active-theme-5 {
      border: 2px solid rgba(0, 255, 0, 1);
      background-color: rgba(0, 255, 0, 0.5);
      box-shadow: 0 0 7px rgba(0, 255, 0, 1);
}

.square-tetromino-active-theme-5 {
      border: 2px solid rgba(0, 255, 255, 1);
      background-color: rgba(0, 255, 255, 0.5);
      box-shadow: 0 0 10px rgba(0, 255, 255, 1);
}

.line-tetromino-active-theme-5 {
      border: 2px solid rgba(128, 0, 128, 1);
      background-color: rgba(128, 0, 128, 0.5);
      box-shadow: 0 0 10px rgba(128, 0, 128, 1);
}

.z-tetromino-active-theme-5 {
      border: 2px solid rgba(255, 255, 0, 1);
      background-color: rgba(255, 255, 0, 0.5);
      box-shadow: 0 0 10px rgba(255, 255, 0, 1);
}

.hard-drop-active {
      border: 2px solid white;
}

.touch-controls-section {
      display: flex;
      /* width: 100%; */
      gap: 80px;
      justify-content: center;
}

.controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
}

.button {
      background-color: red;
      padding: 2px;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      color: black;
}

.middle-row {
      display: flex;
      gap: 65px;
}

@media (max-width: 900px) {
      @keyframes shaking-animation {
            0%,
            40%,
            80% {
                  transform: scale(0.85) rotate(2deg);
            }
            20%,
            60%,
            100% {
                  transform: scale(0.85) rotate(-2deg);
            }
      }

      /* .player-section {
            transform: scale(0.85);
      } */
}

.game-modes-section {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      z-index: 1;
      scroll-behavior: smooth;
}

.game-modes-section::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
}

.game-modes-section::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
}

.game-modes-section::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: black;
}

.game-modes {
      padding: 20px;
      border-radius: 20px;
      width: 90%;
      margin: 20px;
}

.game-modes-heading {
      color: white;
      font-size: 1.9em;
      margin-top: 20px;
}

.setting-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
      padding: 10px;
      border-radius: 10px;
}

.challenges {
      margin-top: 10px;
}

.challenges-sub-type {
      margin-top: 10px;
}

.sub-type-heading {
      color: white;
      margin-bottom: 15px;
}

.option {
      border: 2px solid transparent;
      max-width: 800px;
      border-radius: 5px;
      margin-top: 10px;
      padding: 8px;
      cursor: pointer;
      min-width: 250px;
      display: flex;
      gap: 20px;
      font-size: 0.9em;
}

.option-theme-0 {
      background-color: rgba(255, 0, 0, 0.1);
}
.option-theme-1 {
      background-color: rgba(0, 255, 0, 0.1);
}
.option-theme-2 {
      background-color: rgba(128, 0, 128, 0.1);
}
.option-theme-3 {
      background-color: rgba(255, 255, 0, 0.1);
}
.option-theme-4 {
      background-color: rgba(0, 255, 255, 0.1);
}

.challenge-number {
      min-width: 30px;
}

.challenge-name {
      flex-grow: 1;
}

@media (max-width: 800px) {
      .game-modes-section::-webkit-scrollbar {
            width: 2px;
      }
      .game-modes-section::-webkit-scrollbar-thumb {
            background-color: transparent;
      }
}

@media (max-width: 500px) {
      .game-modes {
            padding: 0;
            padding-top: 20px;
            margin: 5px;
            margin-top: 20px;
      }
      .option {
            font-size: 1em;
      }
}

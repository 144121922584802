.go-back-button {
      position: absolute;
      left: 15px;
      top: 15px;
      font-size: 20px;
      cursor: pointer;
      background-color: black;
      color: white;
      border-radius: 50%;
      height: 40px;
      width: 40px;
}

@media (max-width: 800px) {
      .go-back-button {
            left: 10px;
            top: 10px;
            height: 30px;
            width: 30px;
            font-size: 17px;
      }
}

@media (max-width: 500px) {
      .go-back-button {
            left: 10px;
            top: 10px;
            height: 25px;
            width: 25px;
            font-size: 15px;
      }
}

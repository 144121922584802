@font-face {
      font-family: ff-mark;
      src: url(../../assets/fonts/FF-Mark/MarkPro-Medium.otf);
}

.page {
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            url("../../assets/images/homePageBackgroundImage.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      display: flex;
      flex-direction: column;
      height: 100vh;
      align-items: center;
      font-family: ff-mark;
      letter-spacing: 0.08rem;
      background-color: rgba(0, 0, 0, 1);
}

.main {
      flex-grow: 1;
      width: 70vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}

#introduction {
      color: white;
      width: 50%;
      font-size: 32px;
}

.button {
      border: 2px solid rgba(0, 255, 255, 1);
      background-color: rgba(0, 255, 255, 0.5);
      padding: 10px;
      margin: 4px;
}
.button:hover {
      box-shadow: 0 0 10px rgba(0, 255, 255, 1);
}

.button-link {
      font-weight: bold;
      padding: 1px !important;
      margin-right: 4px;
      margin-left: 4px;
      color: white;
      text-decoration: none;
}

.connected-key-board {
      margin: 5px;
      padding: 10px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 700px;
      border-radius: 20px;
      text-align: center;
}

.container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 10px;
      gap: 10px;
      align-items: center;
}

.image-loader {
      width: 200px;
      height: 109px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      border-radius: 10px;
      color: white;
}

.key-board-image {
      width: 200px;
}

@media (max-width: 900px) {
      .connected-key-board {
            flex-direction: column;
            flex-grow: 1;
            max-width: 400px;
      }
      .key-board-image {
      }
}

.menu-overlay-section {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      z-index: 1;
      flex-direction: column;
      /* animation: unset !important; */
      animation-duration: 1s !important;
      overflow-y: scroll;
      overflow-x: hidden;
}

.menu-overlay-section::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
}

.menu-overlay-section::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
}

.menu-overlay-section::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: white;
}

.menu-overlay-close-button {
      position: absolute;
      right: 5px;
      top: 5px;
}

.container {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: min-content;
}

.option {
      width: 500px;
      margin: 5px;
      font-size: 20px;
      padding: 5px;
      cursor: pointer;
      z-index: 100;
      border: 2px solid transparent;
}

.option-theme-0:focus {
      border: 2px solid rgba(255, 0, 0, 1);
      background-color: rgba(255, 0, 0, 0.3);
      box-shadow: 0 0 8px rgba(255, 0, 0, 1);
}

.option-theme-1:focus {
      border: 2px solid rgba(0, 255, 0, 1);
      background-color: rgba(0, 255, 0, 0.3);
      box-shadow: 0 0 8px rgba(0, 255, 0, 1);
}

.option-theme-2:focus {
      border: 2px solid rgba(128, 0, 128, 1);
      background-color: rgba(128, 0, 128, 0.3);
      box-shadow: 0 0 8px rgba(128, 0, 128, 1);
}

.option-theme-3:focus {
      border: 2px solid rgba(255, 255, 0, 1);
      background-color: rgba(255, 255, 0, 0.3);
      box-shadow: 0 0 8px rgba(255, 255, 0, 1);
}

.option-theme-4:focus {
      border: 2px solid rgba(0, 255, 255, 1);
      background-color: rgba(0, 255, 255, 0.3);
      box-shadow: 0 0 5px rgba(0, 255, 255, 1);
}

.challenge-details {
      color: white;
      padding: 5px;
      margin: 10px;
      margin-bottom: 50px;
      max-width: 500px;
      padding: 10px;
      text-align: center;
      font-size: 0.9em;
}

.challenge-details-theme-0 {
      border: 2px solid rgba(255, 0, 0, 1) !important;
}
.challenge-details-theme-1 {
      border: 2px solid rgba(0, 255, 0, 1) !important;
}

.challenge-details-theme-2 {
      border: 2px solid rgba(128, 0, 128, 1) !important;
}

.challenge-details-theme-3 {
      border: 2px solid rgba(255, 255, 0, 1) !important;
}
.challenge-details-theme-4 {
      border: 2px solid rgba(0, 255, 255, 1) !important;
}

.controls-icons {
      position: absolute;
      bottom: 10px;
      left: 10px;
      display: flex;
      gap: 15px;
}

.logo {
      position: absolute;
      right: 8px;
      bottom: 8px;
      font-size: 18px;
      color: #fff;
      user-select: none;
}

.logo-theme-0 b {
      color: white;
      text-shadow: 0 -40px 100px, 0 0 0px, 0 0 13px rgba(255, 0, 0, 1),
            0 0 8px rgba(255, 0, 0, 1), 0 0 2px rgba(255, 0, 0, 1),
            0 10px 3px #000;
}
.logo-theme-1 b {
      color: white;
      text-shadow: 0 -40px 100px, 0 0 0px, 0 0 13px rgba(0, 255, 0, 1),
            0 0 8px rgba(0, 255, 0, 1), 0 0 2px rgba(0, 255, 0, 1),
            0 10px 3px #000;
}
.logo-theme-2 b {
      color: white;
      text-shadow: 0 -40px 100px, 0 0 0px, 0 0 13px rgba(128, 0, 128, 1),
            0 0 8px rgba(128, 0, 128, 1), 0 0 2px rgba(128, 0, 128, 1),
            0 10px 3px #000;
}
.logo-theme-3 b {
      color: white;
      text-shadow: 0 -40px 100px, 0 0 0px, 0 0 13px rgba(255, 255, 0, 1),
            0 0 8px rgba(255, 255, 0, 1), 0 0 2px rgba(255, 255, 0, 1),
            0 10px 3px #000;
}
.logo-theme-4 b {
      color: white;
      text-shadow: 0 -40px 100px, 0 0 0px, 0 0 13px rgba(0, 255, 255, 1),
            0 0 8px rgba(0, 255, 255, 1), 0 0 2px rgba(0, 255, 255, 1),
            0 10px 3px #000;
}
.logo b span {
      animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2) {
      animation: blink linear infinite 3s;
}
@keyframes blink {
      78% {
            color: inherit;
            text-shadow: inherit;
      }
      79% {
            color: #333;
      }
      80% {
            text-shadow: none;
      }
      81% {
            color: inherit;
            text-shadow: inherit;
      }
      82% {
            color: #333;
            text-shadow: none;
      }
      83% {
            color: inherit;
            text-shadow: inherit;
      }
      92% {
            color: #333;
            text-shadow: none;
      }
      92.5% {
            color: inherit;
            text-shadow: inherit;
      }
}

@media (max-width: 800px) {
      .option {
            width: 300px;
      }
}

@media (max-width: 500px) {
      .option {
            font-size: 16px;
      }
}

@media (max-width: 500px) {
      .option {
            width: 230px;
      }
}

.game-modes-section {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 1;
}

.game-modes-section::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
}

.game-modes-section::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
}

.game-modes-section::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: black;
}

.game-modes {
      padding: 20px;
      border-radius: 20px;
      width: 90%;
      margin: 20px;
}

.game-modes-heading {
      color: white;
      font-size: 30px;
      margin-top: 20px;
}

.settings-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 1;
      overflow-y: scroll;
      scroll-behavior: smooth;
}

.settings-overlay::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
}

.settings-overlay::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
}

.settings-overlay::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: black;
}

.settings-overlay-close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
}

.settings {
      padding: 10px;
      padding-top: 20px;
      border-radius: 20px;
      width: 90%;
      margin: 20px;
}

.controller-settings-heading {
      color: white;
      font-size: 30px;
      margin-top: 20px;
}
.connected-controllers {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      gap: 15px;
}

.controller-active {
      background-color: green;
}

@media (max-width: 800px) {
      .connected-controllers {
            flex-wrap: wrap;
            flex-direction: row;
      }
      .settings-overlay::-webkit-scrollbar {
            width: 2px;
      }

      .settings-overlay::-webkit-scrollbar-thumb {
            background-color: transparent;
      }
}

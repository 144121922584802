.setting-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 500px;
      max-width: 500px;
      margin-top: 20px;
      padding: 10px;
      border-radius: 10px;
}

.setting {
      font-style: italic;
      font-weight: bold;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 20px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      min-width: 200px;
      text-align: center;
}

@media (max-width: 800px) {
      .setting-container {
            min-width: unset;
      }
      .setting {
            min-width: unset;
      }
}

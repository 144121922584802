.card-three {
      font-style: italic;
      font-weight: bold;
      font-size: 15px;
      background-color: white;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 20px;
      color: black;
      margin: 5px;
      border: 1px solid black;
      cursor: pointer;
      position: relative;
      width: 110px;
}

.card-three-theme-0,
.card-three-theme-1,
.card-three-theme-2,
.card-three-theme-3 {
      color: white;
      border: 0;
      background-color: transparent;
}

.card-three:focus {
      background-color: black;
      color: white;
}

.card-three-theme-0:focus {
      border: 2px solid rgba(255, 0, 0, 1) !important;
      box-shadow: 0 0 10px rgba(255, 0, 0, 1);
      background-color: rgba(255, 0, 0, 0.3);
}

.card-three-theme-1:focus {
      border: 2px solid rgba(0, 255, 0, 1) !important;
      background-color: rgba(0, 255, 0, 0.3);
      box-shadow: 0 0 10px rgba(0, 255, 0, 1);
}
.card-three-theme-2:focus {
      border: 2px solid rgba(128, 0, 128, 1) !important;
      background-color: rgba(128, 0, 128, 0.3);
      box-shadow: 0 0 10px rgba(128, 0, 128, 1);
}
.card-three-theme-3:focus {
      border: 2px solid rgba(255, 255, 0, 1) !important;
      background-color: rgba(255, 255, 0, 0.3);
      box-shadow: 0 0 10px rgba(255, 255, 0, 1);
}

.card-three-theme-4:focus {
      border: 2px solid rgba(0, 255, 255, 1) !important;
      background-color: rgba(0, 255, 255, 0.3);
      box-shadow: 0 0 10px rgba(0, 255, 255, 1);
}

.game-result-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1000;
      animation: opacity-animation 1s ease-in-out;
      overflow-y: scroll;
      overflow-x: hidden;
      font-size: 14px;
}

.game-result-section::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
}

.game-result-section::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
}

.game-result-section::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: gray;
}

@keyframes opacity-animation {
      from {
            opacity: 0;
      }
      to {
            opacity: 1;
      }
}

.container {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: min-content;
}

.game-result-container {
      display: flex;
      margin-bottom: 10px;
      position: relative;
      z-index: 100;
      align-items: end;
      max-width: 95vw;
      overflow-x: scroll;
      gap: 20px;
}
.game-result-container::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: transparent;
}

.game-result-container::-webkit-scrollbar {
      height: 5px;
      background-color: transparent;
}

.game-result-container::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: gray;
}

.next-challenge {
      color: white;
      padding: 5px;
      width: 100%;
      margin-bottom: 15px;
      /* max-width: 280px; */
      text-align: center;
      font-size: 0.8em;
      width: 95vw;
      max-width: 500px;
}

.next-challenge-theme-0 {
      border: 2px solid rgba(255, 0, 0, 1) !important;
}
.next-challenge-theme-1 {
      border: 2px solid rgba(0, 255, 0, 1) !important;
}

.next-challenge-theme-2 {
      border: 2px solid rgba(128, 0, 128, 1) !important;
}

.next-challenge-theme-3 {
      border: 2px solid rgba(255, 255, 0, 1) !important;
}
.next-challenge-theme-4 {
      border: 2px solid rgba(0, 255, 255, 1) !important;
}
.play-again-button {
      cursor: pointer;
      color: white;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0.9em;
      border: 2px solid transparent;
      min-width: 130px;
}

.custom-menu {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      max-width: 300px;
}

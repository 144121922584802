.card-two-theme-0 {
      background-color: rgba(255, 0, 0, 0.3) !important;
}
.card-two-theme-1 {
      background-color: rgba(0, 255, 0, 0.3) !important;
}
.card-two-theme-2 {
      background-color: rgba(128, 0, 128, 0.3) !important;
}
.card-two-theme-3 {
      background-color: rgba(255, 255, 0, 0.3) !important;
}

.card-two-theme-4 {
      background-color: rgba(0, 255, 255, 0.3) !important;
}

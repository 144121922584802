.page {
      width: 100vw;
      height: 100svh;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: monospace;
      animation: none !important;
      font-size: 16px;
}

.container {
      height: 50px;
      display: flex;
      align-items: center;
}
.loader {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;
      flex-direction: column;
      z-index: 10000;
}
.logo {
      font-size: 100px;
      color: #fff;
      user-select: none;
}

.logo-theme-0 b {
      color: white;
      text-shadow: 0 -40px 100px, 0 0 0px, 0 0 13px rgba(255, 0, 0, 1),
            0 0 8px rgba(255, 0, 0, 1), 0 0 2px rgba(255, 0, 0, 1),
            0 10px 3px #000;
}

.logo b span {
      animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2) {
      animation: blink linear infinite 3s;
}

.loading-text {
      color: white;
      font-size: 13px;
}

.lets-go-button {
      padding: 15px;
      padding-top: 3px;
      padding-bottom: 3px;
      border: 2px solid rgba(255, 0, 0, 1);
      background-color: rgba(255, 0, 0, 0.3);
      border-radius: 15px;
      font-family: monospace;
      cursor: pointer;
      color: white;
}
.lets-go-button:hover {
      box-shadow: 0 0 8px rgba(255, 0, 0, 1);
}
@keyframes blink {
      78% {
            color: inherit;
            text-shadow: inherit;
      }
      79% {
            color: #333;
      }
      80% {
            text-shadow: none;
      }
      81% {
            color: inherit;
            text-shadow: inherit;
      }
      82% {
            color: #333;
            text-shadow: none;
      }
      83% {
            color: inherit;
            text-shadow: inherit;
      }
      92% {
            color: #333;
            text-shadow: none;
      }
      92.5% {
            color: inherit;
            text-shadow: inherit;
      }
}

@media (max-width: 600px) {
      .logo {
            font-size: 70px;
      }
}
